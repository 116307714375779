<template>
    <div>
        <section class="hero is-bold is-primary is-medium">
            <slot></slot>
            <div class="hero-body">
                <div class="container has-text-centered">
                    <h1 class="title">
                    The new standard in time management
                    </h1>
                    <h2 class="subtitle">
                    If your attention must be focused on several projects at the same time and do not always know where to turn, this tool is for you. TimeNitro is a personal time management tool that was designed with the idea of maximizing the chances of reaching your goals. Take control of your schedule!
                    </h2>
                </div>
            </div>
            
            <div class="box cta">
                <p class="has-text-centered">
                    <span class="tag is-primary">New</span> First time on TimeNitro? Have a look at our <u><b><a href="#" @click="ToggleVideo()"> introductory video</a></b></u> to learn more about us. 
                </p>
                <span>
                    
                </span>
                <div class="modal" v-if=showVideo :class="{'is-active':showVideo}">
                    <div class="modal-background"></div>
                        <div class="modal-card">
                            <header class="modal-card-head buttons is-right mb-0 py-2">
                                <button class="delete" aria-label="close" @click="ToggleVideo"></button>
                            </header>
                            <section class="modal-card-body">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/ATkZoN8h6XQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </section>
                        </div>
                </div>                
            </div>
        </section>      
         <section class="container">
            <div class="columns features">
                <div class="column is-4">
                    <div class="card is-shady">
                        <div class="card-content">
                            <figure class="image is-4by3">
                                <img src="@/images/overwhelmed.jpg" alt="Overwhelmed">
                            </figure>
                            <div class="content">
                                <h4>Managing personal time</h4>
                                <p>Time management courses are not part of professionnal's curriculum. Getting promoted to a management position sometimes
                                    means requests come from everywhere. There needs to be tools to help manage that chaos. </p>
                                <router-link to="/purpose">Learn more</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="card is-shady">
                        <div class="card-content">
                            <figure class="image is-4by3">
                                <img src="@/images/agility.jpg" alt="Agility">
                            </figure>
                            <div class="content">
                                <h4>Borrowing from Agility.</h4>
                                <p>The world of software programming found agility in response to the difficulty of traditional planning. Scrum techniques
                                    are helping teams everywhere focus on short term success to reach long term goals.
                                </p>
                                <router-link to="/intent">Learn more</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="card is-shady">
                        <div class="card-content">
                            <figure class="image is-4by3">
                                <img src="@/images/mgt_tools.jpg" alt="Management tools">
                            </figure>
                            <div class="content">
                                <h4>Management techniques</h4>
                                <p>TimeNitro brings together a series of management techniques that have proven to be successfull over time. Since busy professionals 
                                   already have too many things to do, our tools focus on the speed of use.</p>
                                <router-link to="/knowledge">Learn more</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
</template>

<script>
export default {
    name: "Home",
    data() {
        return {
            showVideo: false,
        }
    },
    methods: {
        ToggleVideo() {
            this.showVideo = !this.showVideo;
        },
    }
}
</script>